import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";
import Select from "react-select";

const ManageUpcQuantityWarehouse = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const minUpcqty = useRef(null);
  // const maxUpcqty = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const searchProduct = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;
    const warehouseID = selectedWarehouseId; // Get the warehouse ID from the state

    try {
      const url =
        buildLink("productInfo") +
        `&item=${item}&v2=true` +
        `&user_id=${stateCom.userID}` +
        `&warehouse_id=${warehouseID}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          setProductInf(response.data.data);
          minUpcqty.current.value = response.data.data.min_bin_quantity;
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          playSuccessSound();
          // maxUpcqty.current.value = response.data.data.max_upc_quantity;
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function updateUpcQty() {
    const userID = getUserIdFromLocalStorage();
    setLoading(true);
    const product_id = codePr.current.value;
    const min_quantity = minUpcqty.current.value;
    // const max_quantity = maxUpcqty.current.value;
    const user_id = userID;

    const obj = JSON.stringify({
      product_id,
      min_quantity,
      // max_quantity,
      user_id,
      warehouse_id: selectedWarehouseId,
    });
    if (typeof productInf === "string") {
      if (productInf.upc === "") {
        obj.min_quantity = productInf.min_bin_quantity;
        // obj.max_quantity = productInf.max_bin_quantity;
      } else {
        obj.min_quantity = productInf.min_upc_quantity;
        // obj.max_quantity = productInf.max_upc_quantity;
      }
    }
    try {
      const url = buildLink("manageUPCQty") + `&v2=true`;
      await axios.post(url, JSON.parse(obj)).then((response) => {
        if (response.data.success) {
          playSuccessSound();
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          minUpcqty.current.value = "";
          // maxUpcqty.current.value = "";
          codePr.current.value = "";
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();

    const savedWarehouse = Cookies.get("selectedWarehousemanageupc");
    if (savedWarehouse) {
      setSelectedWarehouseId(savedWarehouse);
    }
  }, []);

  const handleWarehouseChange = (selectedOption) => {
    const warehouseId = selectedOption ? selectedOption.value : null;
    setSelectedWarehouseId(warehouseId);

    // Save to cookies for 1 day
    if (warehouseId) {
      Cookies.set("selectedWarehousemanageupc", warehouseId, { expires: 1 });
    } else {
      Cookies.remove("selectedWarehousemanageupc");
    }
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-50">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Manage BIN Quantity</h2>
            </div>
          </div>
        </div>
        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => searchProduct(e)}>
            <div className="flex flex-col justify-center gap-3">
              {/* Product ID or SKU field */}
              <div className="relative">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                  placeholder="Product ID Or Sku"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                  />
                </div>
              </div>

              {/* Warehouse Select and Button */}
              <div className="flex flex-row gap-3 w-full">
                {loadingWarehouses ? (
                  <div className="text-center text-dbase">
                    <Loader />
                  </div>
                ) : error ? (
                  <p className="text-dbase">{error}</p>
                ) : (
                  <Select
                    placeholder="Select Warehouse"
                    required
                    onChange={handleWarehouseChange}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "7px",
                        borderColor: state.isFocused ? "grey" : "grey",
                        padding: "8px 5px",
                      }),
                    }}
                    options={
                      warehouses.length > 0
                        ? warehouses.map((warehouse) => ({
                            value: warehouse.warehouse_id,
                            label: warehouse.warehouse_name,
                          }))
                        : []
                    }
                    value={
                      selectedWarehouseId
                        ? warehouses
                            .map((warehouse) => ({
                              value: warehouse.warehouse_id,
                              label: warehouse.warehouse_name,
                            }))
                            .find(
                              (option) => option.value === selectedWarehouseId
                            )
                        : null
                    }
                    className="w-full"
                  />
                )}
                <button className="bg-dbase rounded-md text-white px-5 py-3  flex items-center justify-center">
                  {loadingSearch ? <Loader /> : <FaSearch />}
                </button>
              </div>
            </div>
          </form>

          <div className="relative flex-1">
            <input
              ref={minUpcqty}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Min Qty"
            />
          </div>

          {/* <div className="relative flex-1">
            <input
              ref={maxUpcqty}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Max Upc Qty"
            />
          </div> */}

          <button
            onClick={() => updateUpcQty()}
            type="submit"
            className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
          >
            {" "}
            {loading ? "Loading..." : "Update"}
          </button>
        </div>

        {productInf && (
          <div className="container">
            <div className="bg-white rounded-md shadow-md w-full px3 py-2 mt-10 ">
              <div className=" flex flex-row gap-5">
                <img src={productInf.image} alt="" />
                <div className=" my-auto">
                  <div className=" flex flex-col justify-center">
                    <h2>SKU: {productInf.sku}</h2>
                    <h2>Qty: {productInf.quantity}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(ManageUpcQuantityWarehouse);
